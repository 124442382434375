import React from 'react';

const ThankYou = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>Thank You!</h1>
      <p>Your order has been submitted successfully. We will contact you shortly.</p>
    </div>
  );
};

export default ThankYou;
