import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="about-us-container">
        <h2>About Us</h2>
        <p className="about-us-description">
          We are a leading Ayurveda company dedicated to helping individuals achieve their health and wellness goals.
          With our time-tested formulations, we aim to provide natural and effective solutions for fertility, wellness, and holistic health.
        </p>
        <p className="about-us-description">
          Our mission is to bring ancient Ayurvedic wisdom to modern-day health practices. We are committed to using
          only the highest quality ingredients sourced from nature to ensure the best outcomes for our customers.
        </p>

        <h3>Our Mission</h3>
        <p className="about-us-description">
          Our mission is to offer natural products that support well-being and fertility. We aim to educate people about the
          benefits of Ayurveda and provide them with effective solutions for a healthy and balanced life.
        </p>

        <h3>Our Values</h3>
        <ul className="about-us-values">
          <li>Integrity</li>
          <li>Quality</li>
          <li>Customer Satisfaction</li>
          <li>Innovation</li>
          <li>Natural Wellness</li>
        </ul>
      </div>
    </section>
  );
};

export default AboutUs;
