import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const handleOrderNow = () => {
    navigate("/form");
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true; // Mute the video initially
      videoRef.current.play().catch((error) => {
        console.error("Autoplay was prevented:", error);
      });
    }
  }, []);

  const unmuteVideo = () => {
    if (videoRef.current) {
      videoRef.current.muted = false; // Unmute the video
    }
  };

  return (
    <div className="main-container">
      <section className="hero-section">
        <div className="hero-wrapper">
          {/* Video Section */}
          <div className="hero-video">
            <video
              ref={videoRef}
              src="./video/Putravardan 30s_1.mp4" // Replace with your video file path
              loop
              playsInline
              className="video-element"
            >
              Your browser does not support the video tag.
            </video>
            <button onClick={unmuteVideo} className="unmute-button">
              Unmute Video
            </button>
          </div>
          {/* Content Section */}
          <div className="hero-content">
            <h1>पुत्रवरदान, शिवलिंगी से बना हर्बल उत्पाद</h1>
            <p>बिना किसी दुष्प्रभाव के 100% आयुर्वेदिक</p>
            <p>मार्केट से डुप्लीकेट सस्ता प्रोडक्ट ना खरीदे</p>
            <button onClick={handleOrderNow} className="btn-order">
              अभी ऑर्डर करें
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
