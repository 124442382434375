import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="header">
      <div className="logo">Putra Vardan</div>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? '×' : '☰'} {/* Display 'X' when menu is open, otherwise show hamburger */}
      </div>
      <nav className={`nav-links ${menuOpen ? 'open' : ''}`}>
        <a href="/">Home</a>
        <a href="about">About Us</a>
        <a href="contact">Contact</a>
        <a href="blog">Blog</a>
        <a href="privacy">Privacy Policy</a>
        <a href="terms">Terms And Condition</a>
      </nav>
    </header>
  );
};

export default Header;
