import React from 'react';
import './FeaturesSection.css';
import ProductSection from './ProductSection';

const FeaturesSection = () => {
  return (
    <section className="features">
      <h2>हमारी विशेषताएँ</h2>
      <div className="features-list">
        <div className="feature-item">
          <div className="feature-icon">
            <img src="img/sm-icon-1.webp" alt="Fertility Icon" />
          </div>
          <h3>शुक्राणु बढ़ाता है</h3>
          <p>स्वाभाविक रूप से शुक्राणु संख्या में वृद्धि करता है।</p>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
            <img src="img/sm-icon-2.webp" alt="Egg Quality Icon" />
          </div>
          <h3>अंडे की गुणवत्ता में सुधार</h3>
          <p>प्राकृतिक रूप से अंडे की गुणवत्ता में सुधार करता है।</p>
        </div>
        <div className="feature-item">
          <div className="feature-icon">
            <img src="img/sm-icon-3.webp" alt="Natural Conception Icon" />
          </div>
          <h3>प्राकृतिक रूप से गर्भधारण</h3>
          <p>प्राकृतिक तरीकों से गर्भधारण की संभावनाओं को बढ़ाता है।</p>
        </div>
      </div>

      {/* Additional Content */}
      <div className="list mx-auto">
        <div className="list__item">
          <div className="list__item-img">
            <img src="img/s1.png" alt="Natural Conception Help" />
          </div>
          <div className="list__item-text">प्राकृतिक गर्भावस्था प्राप्त करने में मदद करता है</div>
        </div>
        <div className="list__item">
          <div className="list__item-img">
            <img src="img/s2.png" alt="Ovulation Support" />
          </div>
          <div className="list__item-text">समय पर ओव्यूलेशन का समर्थन करता है</div>
        </div>
        <div className="list__item">
          <div className="list__item-img">
            <img src="img/s4.png" alt="Egg Growth Support" />
          </div>
          <div className="list__item-text">अंडे की वृद्धि और गुणवत्ता में सुधार करता है</div>
        </div>
        <div className="list__item">
          <div className="list__item-img">
            <img src="img/s3.png" alt="Prevent Early Pregnancy Loss" />
          </div>
          <div className="list__item-text">प्रारंभिक गर्भावस्था हानि को रोकता है</div>
        </div>
        <div className="list__item">
          <div className="list__item-img">
            <img src="img/s5.png" alt="Improves Uterus Environment" />
          </div>
          <div className="list__item-text">गर्भाशय के वातावरण में सुधार करता है</div>
        </div>
      </div>
      <ProductSection />
    </section>
    
  );
};

export default FeaturesSection;
