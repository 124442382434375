import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy">
      <div className="privacy-policy-container">
        <h2>Privacy Policy</h2>
        <p>
          This Privacy Policy outlines how we collect, use, and protect your information when you visit our website.
        </p>

        <div className="policy-section">
          <h3>1. Information Collection</h3>
          <p>
            We collect personal information that you provide to us, such as your name, email address, phone number, and payment details when you place an order or fill out a form.
          </p>
        </div>

        <div className="policy-section">
          <h3>2. Information Use</h3>
          <p>
            The information we collect is used to process your orders, improve our services, and provide you with a better user experience. We may also use the information to send you promotional offers and updates, if you have opted in.
          </p>
        </div>

        <div className="policy-section">
          <h3>3. Data Protection</h3>
          <p>
            We take data security seriously and implement various security measures to protect your personal information from unauthorized access or misuse. However, please be aware that no method of data transmission over the internet is 100% secure.
          </p>
        </div>

        <div className="policy-section">
          <h3>4. Cookies</h3>
          <p>
            Our website may use cookies to enhance your experience. Cookies are small files stored on your device that help us remember your preferences and improve website performance. You can control cookie settings through your browser.
          </p>
        </div>

        <div className="policy-section">
          <h3>5. Third-Party Links</h3>
          <p>
            Our website may contain links to third-party websites. We are not responsible for the privacy practices of these websites. We encourage you to review the privacy policies of any linked websites.
          </p>
        </div>

        <div className="policy-section">
          <h3>6. Your Rights</h3>
          <p>
            You have the right to access, update, or delete your personal information. If you wish to exercise any of these rights, please contact us at the details provided below.
          </p>
        </div>

        <div className="policy-section">
          <h3>7. Changes to this Privacy Policy</h3>
          <p>
            We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date. We encourage you to review this policy periodically.
          </p>
        </div>

        <div className="policy-section">
          <h3>8. Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
            <br />
            <strong>Email:</strong> support@example.com
            <br />
            <strong>Phone:</strong> +91 7096695448
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
