import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import FormSection from './components/FormSection';
import ThankYou from './components/ThankYou';
import FooterSection from './components/FooterSection';
import TestimonialSection from './components/TestimonialSection';
import GalleryComponent from './components/GalleryComponent';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Blog from './components/Blog';
import BlogDetails from './components/BlogDetails';
import './App.css';

const App = () => {
  return (
    <Router>
      {/* Global Meta Tags */}
      <Helmet>
        <title>Putravardan - Ayurvedic Solution for Conceiving a Baby</title>
        <meta
          name="description"
          content="Putravardan offers safe and effective Ayurvedic solutions for conceiving a baby naturally. Trusted by thousands of families."
        />
        <meta
          name="keywords"
          content="Ayurvedic baby conceive, natural fertility solution, Putravardan Ayurveda, fertility Ayurveda, baby planning"
        />
        <meta name="author" content="Putravardan Ayurveda" />
        <meta property="og:title" content="Putravardan - Ayurvedic Solution for Conceiving a Baby" />
        <meta
          property="og:description"
          content="Discover safe and natural Ayurvedic treatments to conceive a baby with Putravardan. Trusted by families for generations."
        />
        <meta property="og:image" content="/img/combo2.webp" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.putravardan.in" />
        <meta property="og:site_name" content="Putravardan" />
        <link rel="icon" href="/img/f-icon.png" />
      </Helmet>

      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <HeroSection />
              <FeaturesSection />
              <FormSection />
              <GalleryComponent />
              <TestimonialSection />
            </>
          }
        />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="/form" element={<FormSection />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDetails />} /> {/* Dynamic route */}
      </Routes>
      <FooterSection />

      {/* Static Call Now Button */}
      <a href="tel:+917096695448" className="call-now-button">
        <span className="phone-icon">📞</span> <span className="call-text">अभी फ़ोन करें</span>
      </a>

      {/* Link to navigate to Form Section */}
      <Link to="/form" className="order-now-button">
        <span className="order-icon">🛒</span> अभी ऑर्डर करें
      </Link>
    </Router>
  );
};

export default App;
