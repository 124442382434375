import React from 'react';
import './TermsAndConditions.css'; // Add the CSS file for styling

const TermsAndConditions = () => {
  return (
    <section className="terms-section">
      <div className="terms-container">
        <h2>Terms and Conditions</h2>

        <div className="terms-content">
          <h3>1. Introduction</h3>
          <p>These terms and conditions govern the use of our website and services. By accessing or using this website, you agree to comply with these terms.</p>

          <h3>2. Use of the Website</h3>
          <p>You are granted a non-exclusive, non-transferable license to access and use the website for personal or commercial purposes. Any unauthorized use of the website is prohibited.</p>

          <h3>3. Privacy Policy</h3>
          <p>We value your privacy. Please review our Privacy Policy for information on how we collect and use your personal data.</p>

          <h3>4. Products and Services</h3>
          <p>Our products and services are provided as described on the website. We do not guarantee that the products will meet your expectations.</p>

          <h3>5. Payment and Pricing</h3>
          <p>All prices listed on our website are in Indian Rupees and may be subject to change. We accept various forms of payment.</p>

          <h3>6. Limitation of Liability</h3>
          <p>We are not liable for any damages or losses arising from the use of this website or products purchased from the website.</p>

          <h3>7. Governing Law</h3>
          <p>These terms and conditions are governed by the laws of India, and any disputes shall be resolved in the jurisdiction of the courts in India.</p>

          <h3>8. Modifications</h3>
          <p>We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page.</p>

          <h3>9. Contact Information</h3>
          <p>If you have any questions about these terms and conditions, please contact us at <a href="mailto:shiventerprise1710@gmail.com">shiventerprise1710@gmail.com</a>.</p>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
