import React from "react";
import "./TestimonialSection.css";

const testimonials = [
  {
    name: "राजेश चौहान",
    image: "img/client-2.jpg",
    comment:
      "शुक्राणु की कमी के कारण पिछले 7 वर्षों से मुझे बच्चे पैदा करने से रोक दिया गया था, लेकिन यह इलाज से महज़ 7 महीने में उम्मीद की किरण नजर आने लगी है।",
  },
  {
    name: "नाजिर हुसैन",
    image: "img/client-3.jpg",
    comment:
      "मैं पहले से ही आयुर्वेदिक दवाओं पर भरोसा करता था, इस दवा ने मुझे बेहतर महसूस कराया। परिणाम प्राप्त हुआ।",
  },
  {
    name: "वरुणभाई दवे",
    image: "img/client-4.jpg",
    comment:
      "मेरे एक दोस्त ने मुझे इस दवा के बारे में बताया, मैंने इसे ऑर्डर किया और नतीजा सिफर रहा एक महीने में।",
  },
  {
    name: "पराग सिसोदिया",
    image: "img/client-5.jpg",
    comment:
      "मैंने ऑर्डर दिया और केवल 15 दिनों में परिणाम मिल गया, मैंने अपने अंदर बहुत सारे बदलाव देखे।",
  },
];

const TestimonialSection = () => {
  return (
    <section className="testimonial-section">
      <h2 className="testimonial-heading">टिप्पणियाँ</h2>
      <div className="testimonial-container">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <p className="client-quote">{testimonial.comment}</p>
            <div className="client-info-area">
              <div className="client-info-wrap">
                <div className="client-img">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <div className="client-info">
                  <h3>{testimonial.name}</h3>
                </div>
              </div>
              <div className="quote-icon">
                <i className="flaticon-right-quote-sign">"</i>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialSection;
