import React from 'react';
import { useParams } from 'react-router-dom'; // To fetch the dynamic post ID from the URL
import { blogPosts } from './blogData'; // Import the blog data
import FormSection from './FormSection';
import './BlogDetails.css'; // Add CSS for styling

const BlogDetails = () => {
  const { id } = useParams(); // Get the post ID from the URL
  const post = blogPosts.find(p => p.id === parseInt(id)); // Find the post using the ID

  if (!post) {
    return <h2>Blog post not found!</h2>;
  }

  return (
    <section className="blog-details-section">
      <div className="blog-details-container">
        <h1 className="blog-details-title">{post.title}</h1>
        <p className="blog-post-date">{post.date}</p>
        <img src={post.image} alt={post.title} className="blog-details-image" />
        <div
          className="blog-details-content"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </div>
      <FormSection />
    </section>
  );
};

export default BlogDetails;
