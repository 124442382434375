import React, { useState, useEffect } from 'react';
import './FormSection.css';

const FormSection = () => {
  const [time, setTime] = useState(59 * 60); // 59 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <section className="form-section">
      <h2 className="form-heading">ऑफर खत्म होने से पहले ऑर्डर करें</h2>
     
      <p className="subheading">
        पुत्रवरदान किट ऑर्डर करने के लिए नीचे दिया गया फॉर्म भरें।
      </p>
      <h3 className="offer-highlight">100 Money Back Guarantee</h3>
      <p className="limited-offer">Limited Time 50% Discount</p>

      <form
        action="https://crm.putravardan.in/forms/wtl/c92e1046a950ed149651513ae9f900be"
        method="post"
        className="disable-on-submit"
        enctype="multipart/form-data"
        acceptCharset="utf-8"
        id="myForm"
      >
        <input
          type="hidden"
          name="csrf_token_name"
          value="3e0b2affee9548e64f935c53795eee94"
        />
        <input
          type="hidden"
          name="key"
          value="c92e1046a950ed149651513ae9f900be"
        />
        <input
          type="hidden"
          name="websites"
          id="websites"
          className="form-control"
          value="https://putravardan.in/thankyou.html"
        />

        <label htmlFor="order-name">नाम</label>
        <input
          type="text"
          id="order-name"
          className="order-input"
          name="name"
          placeholder="अपना नाम दर्ज करे"
          required
        />

        <label htmlFor="order-number">नंबर</label>
        <input
          type="tel"
          id="order-number"
          className="order-input"
          name="phonenumber"
          placeholder="अपना फोन नंबर दर्ज करे"
          required
          maxLength="10"
          pattern="[0-9]{10}"
          title="Enter a valid 10-digit mobile number."
        />

        <label htmlFor="order-address">पता</label>
        <input
          type="text"
          id="order-address"
          className="order-input"
          name="address"
          placeholder="अपना पता दर्ज करे"
          required
        />

        <button type="submit" className="submit-button">
        ऑर्डर करे
        </button>
      </form>

      <div className="timer">
        <p>ऑफर समाप्त होने में समय: <strong>{formatTime(time)}</strong></p>
      </div>
    </section>
  );
};

export default FormSection;
