import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Importing React Helmet
import { blogPosts } from './blogData'; // Import the blog data
import './Blog.css'; // Import CSS for styling

const Blog = () => {
  return (
    <section className="blog-section">
      {/* Add Helmet tags for SEO */}
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="पुत्रवरदान आयुर्वेद - प्राकृतिक समाधान द्वारा संतान सुख की प्राप्ति। हमारे ब्लॉग में जानें आयुर्वेद के जड़ी-बूटियों और उपचारों के बारे में जो आपकी मदद कर सकते हैं।"
        />
        <meta
          name="keywords"
          content="पुत्रवरदान, आयुर्वेद, संतान सुख, प्राकृतिक समाधान, जड़ी-बूटियां, स्वास्थ्य"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="पुत्रवरदान आयुर्वेद - ब्लॉग" />
        <meta
          property="og:description"
          content="आयुर्वेद के प्राकृतिक उपचारों के बारे में जानकारी प्राप्त करें। हमारे ब्लॉग में स्वस्थ जीवन और संतान सुख के उपायों के बारे में पढ़ें।"
        />
        <meta property="og:image" content="https://via.placeholder.com/600x300" />
        <meta property="og:url" content="https://yourwebsite.com/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="पुत्रवरदान आयुर्वेद - ब्लॉग" />
        <meta
          name="twitter:description"
          content="हमारे ब्लॉग में आयुर्वेद के प्राकृतिक उपचारों के बारे में पढ़ें। संतान सुख और स्वास्थ्य से जुड़े टिप्स प्राप्त करें।"
        />
        <meta name="twitter:image" content="https://via.placeholder.com/600x300" />
        <title>पुत्रवरदान आयुर्वेद - ब्लॉग</title>
      </Helmet>

      <div className="blog-container">
        <h2>Our Latest Blog Posts</h2>
        <div className="blog-posts">
          {blogPosts.map((post) => {
            // Ensure post.content is a string
            const contentPreview = typeof post.content === 'string'
              ? post.content.replace(/<\/?[^>]+(>|$)/g, '').split(' ').slice(0, 20).join(' ')
              : '';

            return (
              <div key={post.id} className="blog-post">
                <img src={post.image} alt={post.title} className="blog-post-image" />
                <div className="blog-post-content">
                  <h3>{post.title}</h3>
                  <p className="blog-post-date">{post.date}</p>
                  <p>{contentPreview}...</p> {/* Show only first 20 words */}
                  <Link to={`/blog/${post.id}`} className="read-more-link">
                    Read More
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Blog;
