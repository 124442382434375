import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
  return (
    <section className="contact-us">
      <div className="contact-us-container">
        <h2>Contact Us</h2>
        <p className="contact-description">
          We’d love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to get in touch.
        </p>

        <div className="contact-info">
          <div className="contact-item">
            <h3>Phone</h3>
            <p><a href="tel:+917096695448">+91 7096695448</a></p>
          </div>

          <div className="contact-item">
            <h3>Email</h3>
            <p><a href="mailto:shiventerprise1710@gmail.com">shiventerprise1710@gmail.com</a></p>
          </div>
        </div>
        
        <div className="contact-form">
          <h3>Send Us a Message</h3>
          <form action="#" method="POST">
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" required />
            
            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" name="email" required />
            
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="4" required></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
