import React from 'react';
import './GalleryComponent.css';

const GalleryComponent = () => {
  const images = [
    '/img/icon-1.png',
    '/img/icon-2.png',
    '/img/icon-3.png',
    '/img/icon-4.png',
    '/img/icon-5.png',
    '/img/icon-6.png',
  ];

  return (
    <section className="photo-gallery">
      <h2 className="gallery-title">ग्राहकों के प्रतिभाव</h2>
      <div className="gallery-grid">
        {images.map((imgSrc, index) => (
          <div className="gallery-item" key={index}>
            <img src={imgSrc} alt={`Gallery item ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default GalleryComponent;
